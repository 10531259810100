import React from 'react';
import styled, { css } from 'styled-components';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import {
  forScreenAtMost680pxWide,
  forScreenAtMost800pxWide,
} from '../styles/mediaQueries';

const NotFoundPage = (): JSX.Element => (
  <Layout footerBoxContent={FooterBoxContent.NotFound} title="Page not found">
    <Wrapper>
      <BigText>404</BigText>
      <Content>
        <Header>Something went wrong</Header>
        <Description>
          This page is unavailable. You don&apos;t have access or this link
          doesn&apos;t exist anymore.
        </Description>
        <ExternalButton href="/">Go back home</ExternalButton>
      </Content>
    </Wrapper>
  </Layout>
);

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30rem 0 1rem 0;
  padding: 1rem;

  ${forScreenAtMost800pxWide(css`
    margin: 20rem 0 1rem 0;
  `)}
`;

const BigText = styled.span`
  font-weight: 700;
  font-size: 400px;
  position: absolute;
  color: #103f46;
  top: 85px;

  ${forScreenAtMost800pxWide(css`
    top: 100px;
    font-size: 260px;
  `)}

  ${forScreenAtMost680pxWide(css`
    top: 180px;
    font-size: 160px;
  `)}
`;

const Description = styled.div`
  color: #678b91;
  max-width: 500px;
  font-size: 18px;

  ${forScreenAtMost800pxWide(css`
    font-size: 14px;
  `)}
`;

const Header = styled.h1`
  color: #c3e5d3;
  font-size: 38px;
  position: relative;
  margin-bottom: 0;

  ${forScreenAtMost800pxWide(css`
    font-size: 36px;
  `)}
`;

const ExternalButton = styled.a`
  font-size: 14px;
  padding: 16px 14px;
  color: ${({ theme }) => theme.colors.texts.primaryAccented};
  background: ${({ theme }) => theme.colors.accents.primary};
  white-space: nowrap;
  border-radius: 51px;
  text-decoration: none;
  transition: 0.2s background;
  text-align: center;
  max-width: 120px;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) =>
      theme.colors.accents.secondaryAccentedTransparent};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export default NotFoundPage;
